import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { MobileFooter } from '@outdoorly/mobile-footer';

const GET_FOOTER = gql`
  query GetNav {
    shop {
      navigation {
        secondary {
          id
          name
          jsonContent
        }
      }
    }
  }
`;
{
  /* <Stack spacing={4} minW={32}>
<Text {...headerStyle}>Company</Text>
<Text {...linkStyle}>About us</Text>
<Text {...linkStyle}>Press</Text>
<Text {...linkStyle}>Contact Us</Text>
<Text {...linkStyle}>{`Careers - We're hiring!`}</Text>
</Stack>
<Stack spacing={4} minW={32}>
<Text {...headerStyle}>Resources</Text>
<Text {...linkStyle}>Add a qualification</Text>
<Text {...linkStyle}>Help</Text>
<Text {...linkStyle}>Shipping</Text>
<Text {...linkStyle}>Returns</Text>
</Stack> */
}

const COMMON_ITEMS = [
  {
    name: 'Company',
    children: [
      {
        name: 'About us',
        linked_object: { url: '/about' },
      },
      {
        name: 'Press',
        linked_object: { url: 'mailto:press@outdoorly.com' },
      },
      {
        name: 'Contact Us',
        linked_object: { url: '/contact' },
      },
      {
        name: "Careers",
        linked_object: { url: '/about#jobs' },
      },
    ],
    linked_object: {},
  },
  {
    name: 'Resources',
    children: [
      {
        name: 'Add a qualification',
        linked_object: { url: '/profile/qualifications' },
      },
      {
        name: 'Help',
        linked_object: { url: '/contact' },
      },
    ],
    linked_object: {},
  },
];

export const Footer: React.FC<unknown> = () => {
  const { data } = useQuery(GET_FOOTER);

  const menuItems = data?.shop.navigation.secondary?.jsonContent
    ? JSON.parse(data?.shop.navigation.secondary?.jsonContent)
    : [];

  return <MobileFooter footerItems={[...menuItems, ...COMMON_ITEMS]} />;
};

export default Footer;
