import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { Footer } from './footer';
import { AuthWall } from './auth-wall';
import { ErrorBoundary } from '@sentry/react';
import { ErrorView } from './error-view';

const LazyResponsiveNav = dynamic(() => import('./responsive-nav'));

export const PageLayout = ({
  includeHeader = true,
  includeFooter = true,
  authWall = true,
  bodyPaddingBottom = 8,
  children,
  ...props
}) => {
  const content = (
    <ErrorBoundary fallback={ErrorView}>
      <Flex
        minHeight="100vh"
        direction="column "
        backgroundColor="scheme.bgPrimary"
        {...props}
      >
        {includeHeader && <LazyResponsiveNav />}
        <ErrorBoundary fallback={ErrorView}>
          <Flex
            flex={1}
            width="100%"
            position="relative"
            direction="column"
            pb={bodyPaddingBottom}
          >
            {children}
          </Flex>
        </ErrorBoundary>
        {includeFooter && <Footer />}
      </Flex>
    </ErrorBoundary>
  );
  if (authWall) return <AuthWall>{content}</AuthWall>;
  return content;
};

PageLayout.propTypes = {
  authWall: PropTypes.bool,
  bodyPaddingBottom: PropTypes.number,
  children: PropTypes.element,
  includeFooter: PropTypes.bool,
  includeHeader: PropTypes.bool,
};
