import React from 'react';
import { useRouter } from 'next/router';
import propTypes from 'prop-types';

import { useAuth } from '../providers/auth';
import dynamic from 'next/dynamic';
import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/modal';
import { PageSpinner } from './page-spinner';

const LazyLogin = dynamic(() => import('./login').then((mod) => mod.Login));

/**
 * Some parts of the app require a user to be authenticated to view.
 *
 * Because we rely on static site generation even for authenticated pages, we
 * must dynamically load the user's authentication data client side.
 *
 * While waiting for the client-side authentication data to load, we show a
 * Skeleton of the page.
 *
 * When authentication data is loaded, the user will either see the children (if
 * authenticated), or a message telling them they need to authenticate as well
 * as being redirected to the login page.
 * */
export const AuthWall = ({ children }) => {
  const { isNotAuthed, isInitializing, isLoading, isAuthed } = useAuth();
  const router = useRouter();

  const maybeAuthed = (() => {
    if (typeof window !== 'undefined') {
        return window.outdoorlyAuthChecked ? window.outdoorlyMaybeAuthed : true;
    } else return false;
  })();

  const certainlyNotAuthed = (!isInitializing && isNotAuthed) || !maybeAuthed;
  const certainlyAuthed = !isLoading && isAuthed;

  return (
    <>
      {certainlyAuthed ? children : <PageSpinner />}
      <Drawer size="full" isOpen={certainlyNotAuthed} placement="top">
        <DrawerOverlay />
        <DrawerContent>
          <LazyLogin
            isLoading={isLoading}
            handleSignupClick={() => {
              router.push('/signup');
            }}
            handleForgotPassword={() => {
              router.push('/reset-password-request');
            }}
            handleCloseClick={() => {
              router.push('/');
            }}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};

AuthWall.propTypes = {
  children: propTypes.node.isRequired,
};
